<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <b-dropdown
          block
          variant="primary"
          :text="$route.name"
          v-if="$store.state.isMobile"
          menu-class="w-100"
          size="lg"
        >
          <b-dropdown-item
           :to="mn.link"
             v-for="mn in menuEstoque"
            :key="`menuest2_${mn.name}`"
            style="height: 50px; width: 100%; text-align: center;"
           >
           <span v-html="mn.icon"></span>
           {{mn.name}}
           <hr/>
          </b-dropdown-item>
          
        </b-dropdown>
        <div
          class="menuP"
          v-if="
            (!$store.state.isMobile &&
              $store.state.auth.user.tipo == 'Administrador') ||
            $store.state.auth.user.tipo == 'Gerente'
          "
        >
          <router-link
            v-for="mn in menuEstoque"
            :key="`menuest_${mn.name}`"
            class="m cursor"
            :to="mn.link"
            active-class="active"
          >
          
            <span v-html="mn.icon"></span>
            {{ mn.name }}
          </router-link>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  mounted() {
    console.log(this.$route);
  },
  data() {
    return {
      menuEstoque: [
        {
          link: "/estoque/estoque",
          icon:  `<i class="fa fa-boxes"/> `,
          name: "Estoque",
        },
        {
          link: "/estoque/movimentacao",
          icon: `<i class="fa fa-recycle" />`,
          name: "Movimentação",
        },
        {
          link: "/estoque/produtos",
          icon: `<i class="fas fa-pizza-slice" />`,
          name: " Cadastro Produtos",
        },
        {
          link: "/estoque/ingredientes",
          icon: `<i class="fas fa-bacon" />`,
          name: " Cadastro Ingredientes",
        },

        {
          link: "/estoque/inventario",
          icon: `<i class="fas fa-sliders-h" />`,
          name: " Inventário / Balanço",
        },
         {
          link: "/estoque/fornecedores",
          icon: `<i class="fas fa-truck" />`,
          name: " Fornecedores",
        },
         {
          link: "/estoque/pedidos",
          icon: `<i class="fas fa-file-invoice" />`,
          name: " Pedidos de Compra",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>






<style lang="scss" scoped>


</style>
